const sliders = document.querySelectorAll(".slider__item");
const achievementImg = document.querySelectorAll(".ellipse-img img");
const achievementText = document.querySelectorAll(".text-wrapper p");
const ellipseWrapper = document.getElementById("ellipse-bg");
const ellipseBig = document.getElementById("ellipse-1");
const ellipseSmall = document.getElementById("ellipse-2");
const sliderMain = document.getElementsByClassName(`slider`);
const aboutAchievments = document.getElementById('about-achievements');
let timerId;
let currentSliderForInterval = 0;


ellipseWrapper.classList = `ellipse-bg ellipse-bg-color-1`;
ellipseBig.classList = `ellipse-1 ellipse-1-color-1`;
ellipseSmall.classList = `ellipse-2 ellipse-2-color-1`;


const setActiveSlide = (event) => {
    const activeItem = event.target;
    const currentItemID = +activeItem.dataset.set;
    const activeIndex = currentItemID - 1;
    const ellipseColorID = (activeIndex % 5) + 1;
    currentSliderForInterval = activeIndex;

    achievementText.forEach((text, index) => {
        text.classList.add("display-hidden");
        achievementImg[index].classList.add("display-hidden");
        sliders[index].classList.remove("slider__item_active");
    });

    activeItem.classList.add("slider__item_active");
    achievementImg[activeIndex].classList.remove("display-hidden");
    achievementText[activeIndex].classList.remove("display-hidden");

    ellipseWrapper.classList = `ellipse-bg ellipse-bg-color-${ellipseColorID}`;
    ellipseBig.classList = `ellipse-1 ellipse-1-color-${ellipseColorID}`;
    ellipseSmall.classList = `ellipse-2 ellipse-2-color-${ellipseColorID}`;
};

const handlerInterval = () => {
    timerId = setInterval(() => {
        if (currentSliderForInterval >= sliders.length - 1) {
            currentSliderForInterval = -1;
        }
        sliders[currentSliderForInterval + 1].click();
    }, 6000);
};

sliders.forEach((slider) => {
    slider.addEventListener("click", (event) => {
        setActiveSlide(event);
        clearInterval(timerId);
        handlerInterval();
    });
});

const handleScroll = () => {
    const diff = document.documentElement.scrollTop + window.innerHeight - aboutAchievments.offsetTop;
    if (diff > 0) {
        handlerInterval();
        window.removeEventListener('scroll', handleScroll);
    }
};

const diff = document.documentElement.scrollTop + window.innerHeight - aboutAchievments.offsetTop;
if (diff > 0) {
    handlerInterval();
} else {
    window.addEventListener('scroll', handleScroll);
}


$(document).ready(function () {
  const options = {
    direction: "vertical",
    slidesPerView: "auto",
    mousewheel: {
      releaseOnEdges: true,
    },
    nested: true,
    breakpoints: {
      0: {
        enabled: false,
        spaceBetween: 24,
        centeredSlides: false,
      },
      767: {
        enabled: true,
        spaceBetween: 44,
        centeredSlides: true,
      }
    },
  };

  const swiper = new Swiper("#about-projects-slider", options);
});

/*$(document).ready(function () {
    const slidesArr = [{
        imageSrc: 'charity-shop-item1.png',
        title: 'Екопригоди суперкомпанії',
        subtitle: 'Книга-гра, екопоради для найменших',
        bgColor: '#97D700'
    },
    {
        imageSrc: 'charity-shop-item2.png',
        title: 'Екоторбинка «Fight for your dream»',
        subtitle: 'Зручна і стильна торбинка',
        bgColor: '#BB16A3'
    },
    {
        imageSrc: 'charity-shop-item3.png',
        title: 'Пін «Dreamer»',
        subtitle: 'Маленька деталь, яка надихає на великі зміни',
        bgColor: '#00C2DF'
    },
    {
        imageSrc: 'charity-shop-item4.png',
        title: 'Листівка «Create for your dream»',
        subtitle: 'Листівка для тих, хто прагне творити',
        bgColor: '#009b77'
    },
    {
        imageSrc: 'charity-shop-item5.png',
        title: 'Листівка «Dance for your dream»',
        subtitle: 'Листівка для тих, хто танцює по життю',
        bgColor: '#FF7F41'
    },
    ];

    const slidesList = slidesArr.slice(0, 5);
    
    const charityBlock = document.querySelector('.mainPage-charityShop');
    const sliderBg = document.querySelector('.charityshop-slider__bg');
    const sliderContainer = document.querySelector('.charityshop-slider__container');
    const sliderPaginationContainer = document.querySelector('.charityshop-slider__pagination');
    const slideHeader = document.querySelector('.charityshop-slider__slide-header');
    const slideDescription = document.querySelector('.charityshop-slider__slide-description');
    
    const nextSlideBtn = document.querySelector('.charityshop-slider-control__next');
    const prevSlideBtn = document.querySelector('.charityshop-slider-control__prev');
    try {
        slidesList.forEach((slideData, index) => {
            sliderContainer.append(createSlide(slideData));
            sliderPaginationContainer.append(createSlidesPagination(index));
        });
   
    
    
    const slides = document.querySelectorAll('.charityshop-slider__slide');
    const slidesPagination = document.querySelectorAll('.charityshop-slider__pagination li')
    
    let currentSlideIndex = 0;
    const slideWidth = 356;
    const slidesCount = 1;
    const options = {
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slidesPerView: 1,
        setWrapperSize: true,
        simulateTouch: false,
        allowTouchMove: true,
        centeredSlides: true,
        autoplay: {
            delay: 6000,
        }
    };
    
    const swiper = new Swiper(".mySwiper", options);

    swiper.autoplay.stop();

    function isInViewport(el) {
        return el.getBoundingClientRect().top < 0
    }
    let slideAutoPlay = false;
    document.addEventListener('scroll', () => {
        if(slideAutoPlay) return;
        if(isInViewport(charityBlock)){
            swiper.autoplay.start();
            return slideAutoPlay = true;
        }
    });
    
    function createSlide(slideData) {
        const li = document.createElement('li');
        li.classList.add('charityshop-slider__slide', 'swiper-slide');
        const a = document.createElement('a');
        a.setAttribute('href', '#');
        li.append(a);
        const img = document.createElement('img');
        img.setAttribute('src', `../../../images/assets/${slideData.imageSrc}`);
        img.setAttribute('alt', `${slideData.title}`);
        a.append(img);
    
        return li;
    }
    
    function createSlidesPagination(index) {

        const li = document.createElement('li');
        if (index === 0) {
            li.classList.add('charityshop-slider__pagination-active');
        }
    
        return li;
    }
    
    const slideNextBtnHandler = () => {
    
        if (currentSlideIndex < slidesList.length - 1) {
            currentSlideIndex++;
    
            updateSlideDescription(currentSlideIndex);
            swiper.slideNext();
        }
    }
    
    const slidePrevBtnHandler = () => {
    
        if (currentSlideIndex > 0) {
            currentSlideIndex--;
    
            updateSlideDescription(currentSlideIndex);
            swiper.slidePrev();
        }
    }
    
    const updateSlideDescription = (activeSlideIndex) => {
        slideHeader.innerText = slidesList[activeSlideIndex].title;
        slideDescription.innerText = slidesList[activeSlideIndex].subtitle;
        updateSliderPagination(activeSlideIndex);
    }
    
    const updateSliderPagination = (activeSlideIndex) => {
        slidesPagination.forEach(slide => slide.classList.remove('charityshop-slider__pagination-active'));
        slidesPagination[activeSlideIndex].classList.add('charityshop-slider__pagination-active');
    }
    
    nextSlideBtn.addEventListener('click', slideNextBtnHandler);
    prevSlideBtn.addEventListener('click', slidePrevBtnHandler);
    
    swiper.on('slideChange', () => {
    
        currentSlideIndex = swiper.activeIndex;
        updateSlideDescription(currentSlideIndex);
        sliderBg.setAttribute('style', `background-color: ${slidesList[swiper.activeIndex].bgColor}; 
        border-color: ${slidesList[swiper.activeIndex].bgColor}; `);
    });

    } catch (error) { }
});*/
$(document).ready(function () {
    const accessToken = document.querySelector('.follow-us__access-token').textContent;

    const userFeed = new Instafeed({
        get: "user",
        target: "instafeed-container",
        resolution: "low_resolution",
        limit: 3,
        accessToken: accessToken,
        template: `<li class="swiper-slide follow-us__gallery-photo"><a href="{{link}}" target="_blank"><img src="{{image}}"/></a></li>`
    });
    userFeed.run();
  
    function swiperMode() {
        const options = {
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 40
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                    slidesOffsetAfter: 0
                },
                320: {
                    slidesPerView: 1.25,
                    spaceBetween: 20
                },
                slidesPerView: "auto",
                autoplayDisableOnInteraction: false,
                loopedSlides: 3
            }
        };
        const swiper = new Swiper("#instafeed-swiper", options);
        return swiper;
    };
    
    window.onload = location.hostname.includes("klitschkofoundation.org") ? swiperMode() : swiperMode;
});

$(document).ready(function () {
  const descriptionContainer = document.querySelector('.main-projects__wrapper');
  const sliderMainWrapper = document.querySelector('.main-projects__slider-wrapper');
  const forwardSlideBtn = sliderMainWrapper.querySelector('.slider-control__forward');
  const backwardSlideBtn = sliderMainWrapper.querySelector('.slider-control__backward');
  const options = {
    breakpoints: {
    0: {
      slidesPerView: 'auto',
      centeredSlides: true,
      centeredSlidesBounds: true,
    },
    767: {
        slidesPerView: 2,
        spaceBetween: 32
      }
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    setWrapperSize: true,
    simulateTouch: false,
    navigation: {
      nextEl: '.slider-control__forward',
      prevEl: '.slider-control__backward',
    },
  };

  const swiper = new Swiper("#main-projects-slider", options);

  function clickOnSlidenavigation(){
    if(swiper.activeIndex >= 1){
      descriptionContainer.classList.add('hidden-data');
      sliderMainWrapper.classList.add('main-projects__slider-wrapper-slideShowView');
      swiper.params.slidesPerView = 3;
    } else {
      descriptionContainer.classList.remove('hidden-data');
      sliderMainWrapper.classList.remove('main-projects__slider-wrapper-slideShowView');
      swiper.params.slidesPerView = 2;
    }
  }
  
  forwardSlideBtn.addEventListener('click', clickOnSlidenavigation);
  backwardSlideBtn.addEventListener('click', clickOnSlidenavigation);
});

const options = {
    breakpoints: {
        0: {
            enabled: true,
            centeredSlides: true,
            centeredSlidesBounds: true,
            pagination: {
                el: '.with-us-you-can__swiper-pagination',
            }
        },
        767: {
            enabled: false,
            centeredSlides: false,
            centeredSlidesBounds: false,
        }
    },
    slidesPerView: 'auto',
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    setWrapperSize: true,
    simulateTouch: true,
};

const swiper = new Swiper("#with-us-you-can-slider", options);

$(document).ready(function () {
    const buttons = document.querySelectorAll(".main-intro__btn");
    const wordsList = document.querySelectorAll(
        ".main-intro__description span"
    );
    const introText = document.querySelectorAll(".main-intro__text-wrapper p");

    const setActiveSlide = (event) => {
        const activeItem = event.target;
        const currentItemID = +activeItem.dataset.id;
        const activeIndex = currentItemID - 1;

        introText.forEach((item, index) => {
            buttons[index].classList.remove("main-intro__btn-active");
            item.classList.add(`display-hidden`);
        });

        wordsList.forEach((item) =>
            item.classList.remove("main-intro__description-active")
        );

        buttons[activeIndex].classList.add("main-intro__btn-active");
        wordsList[activeIndex].classList.add(
            "main-intro__description-active"
        );
        introText[activeIndex].classList.remove("display-hidden");
    };

    wordsList.forEach((word) => {
        word.addEventListener(`click`, (event) => {
            setActiveSlide(event)
        })
    })

    buttons.forEach((btn) => {
        btn.addEventListener("click", (event) => {
            setActiveSlide(event);
        });
    })
});


$(document).ready(function(){
    const projectsNewsSlider = new Swiper('.project-news-slider', {
        navigation: {
          nextEl: '.project-news-block .swiper-btn-next',
          prevEl: '.project-news-block .swiper-btn-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 8,
                centeredSlides: true,
                centeredSlidesBounds: true
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 32,
              centeredSlides: false,
              centeredSlidesBounds: false
            }
          },
            watchSlidesVisibility: true,
            setWrapperSize: true,
            allowTouchMove: true
    });
})
$('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
      }
    }
});

window.addEventListener('scroll', function() {
  const scrollArrows = document.querySelector(".scroll-arrows-wrapper"),
				overs = [document.querySelector(".top-banner"), document.querySelector(".footer")];
        scrollArrows.classList.toggle("hide", overs.some(el => collides(scrollArrows, el)));
});
function collides(el1, el2) {
  const rect1 = el1.getBoundingClientRect(),
        rect2 = el2.getBoundingClientRect();
  return !(
    rect1.top > rect2.bottom ||
    rect1.bottom < rect2.top
  )
}